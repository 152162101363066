import "$styles/index.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

var randomColor = document.documentElement.classList.add('color-palette-' + getRndInteger(1,7).toString());

console.info("Bridgetown is loaded!")
